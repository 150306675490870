const getters = {
  tabName: (state) => () => {
    return state.tabName
  },
  initialTabName: (state) => () => {
    return state.initialTabName
  }
}

const mutations = {
  setTabName(state, payload) {
    state.tabName = payload
  },
  setInitialTabName(state, payload) {
    state.initialTabName = payload
  }
}

const state = {
  tabName: null,
  initialTabName: null
}

export default {
  state,
  getters,
  mutations
}
