import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import { onAuthStateChanged } from 'firebase/auth'
import { fbauth } from '@/plugins/firebase'

Vue.config.productionTip = false

Vue.directive('blur', {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur()
  }
})

export const eventBus = new Vue()

let app

const unsubscribe = onAuthStateChanged(fbauth, () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
  unsubscribe()
})
