<template>
  <v-card flat tile class="pa-4">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
}
</script>
